










































































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@common-src/mixins/group-component';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { OrgQueryModel } from '@common-src/entity-model/org-entity';
import { UserEntityModel, UserResetPwdModel } from '@common-src/entity-model/user-entity';
import OrgService from '@common-src/service/org';
import UserService, { UserResetPwdService } from '@common-src/service/user-v2';
import { GroupEntityType, ViewModeType } from '@common-src/model/enum';
import OrgTreeComponent from './org-tree.vue';
import OrgUserAddComponent from './org-user-add.vue';
import { UserStoreModule } from '@common-src/store/modules/user';
import UserPwdComponent from '../user/user-pwd.vue';

@Component({
    components: {
        'org-tree-component': OrgTreeComponent,
        'org-user-add-component': OrgUserAddComponent,
        'user-pwd-dialog': UserPwdComponent
    }
})
export default class OrgListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    GroupEntityType = GroupEntityType;
    UserEntityModel = UserEntityModel;
    orgHeadIds = [];
    created() {
        this.initTable({
            service: new UserService(),
            queryModel: new OrgQueryModel(),
            tableColumns: _.filter(UserEntityModel.getTableColumns(), item => {
                if (this.IsCorp) {
                    return item.dataIndex !== 'projectName' && item.dataIndex !== 'orgName';
                }
                return item.dataIndex !== 'projectName';
            })
        });
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as OrgQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`${this.$route.path}?groupId=${groupId}`);
            OrgService.retrieve(groupId).then((res:any) => {
                this.orgHeadIds = res.userIds;
            });
        } else {
            this.$router.push(this.$route.path);
        }
    }

    get PageName() {
        // if (this.IsCorp) {
        //     return '组织架构';
        // }
        return '组织架构';
    }

    /**
     * 是否企业用户
     */
    get IsCorp() {
        return !UserStoreModule.CurrentProjectId;
    }

    /**
     * 选中的组织架构id
     */
    get SelectGroupId() {
        return _.first((this.queryModel as OrgQueryModel).groupIds);
    }

    /**
     * 删除操作
     * @param model 数据模型
     */
    async onDeleteClick(record:any): Promise<boolean> {
        // if (this.IsCorp) {
        //     return this.deleteClick(record);
        // }
        // if (!this.SelectGroupId) {
        //     this.showMessageWarning('请先选择左侧的职能部门');
        //     return;
        // }
        if (!record.orgIds.length) {
            this.showMessageWarning('该用户还没有分配组织架构');
            return;
        }
        this.startFullScreenLoading('正在保存');
        return OrgService.deleteOrgUsers(record.orgIds[0], record.id).then((res) => {
            this.removeModel(record);
            this.showMessageSuccess('移出职能部门成功');
            return true;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    /**
     * 从列表中移除一个数据
     * @param model 数据模型
     */
    // protected removeModel(model: any) {
    //     const index = this.listData.indexOf(model);
    //     if (index > -1) {
    //         this.listData.splice(index, 1);
    //     }
    // }

    appendClick() {
        if (!this.SelectGroupId) {
            this.showMessageWarning('请先选择左侧的组织架构');
            return;
        }
        (this.$refs.formAppendDialog as any).dialogOpen(this.SelectGroupId);
    }

    addCorpClick() {
        const orgId = this.GroupId ? [this.GroupId] : [];
        this.addClick(new UserEntityModel(orgId));
    }

    resetPwdClick(userModel: UserEntityModel) {
        const userPwdModel = new UserResetPwdModel();
        userPwdModel.id = userModel.id;
        (this.$refs.userPwdFormDialog as any).dialogOpen(userPwdModel, new UserResetPwdService(), ViewModeType.UPDATE);
        (this.$refs.userPwdFormDialog as any).dialogTitle = '重置密码';
    }

    userViewClick(model) {
        delete model.password;
        this.viewClick(model);
    }
}

